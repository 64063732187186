import React from 'react'
import { FiCheck, FiStar } from "react-icons/fi";

const AdvancePricingOne = () => {
    return (
        <div className="advance-pricing">
            <div className="inner">
                <div className="row row--0">
                    <div className="col-lg-6">
                        <div className="pricing-left">
                            <h3 className="main-title">Platform Plan</h3>
                            <p className="description">Choose the plan that suits for your company.</p>
                            <div className="price-wrapper">
                                <span className="price-amount"> <sup></sup></span>
                            </div>
                            <div className="pricing-btn-group">
                                <a button className="btn-default" href="/demo">Book Demo</a>
                                <a button className="btn-default btn-border" href="/contact">Contact Us</a>
                            </div>
                            <div className="rating">
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                                <a href="#rating"><FiStar /></a>
                            </div>
                            <span className="subtitle">rated 4.5/5 Stars in 100+ reviews.</span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="pricing-right">
                            <div className="pricing-offer">
                                <div className="single-list">
                                    <h4 className="price-title">Standard +</h4>
                                    <ul className="plan-offer-list">
                                        <li><FiCheck /> Products catalog</li>
                                        <li><FiCheck /> Products specifications</li>
                                        <li><FiCheck />  Availability in location</li>
                                        <li><FiCheck />  Ask for rent</li>
                                    </ul>
                                </div>
                                <div className="single-list mt--40">
                                    <h4 className="price-title">Premium +</h4>
                                    <ul className="plan-offer-list">
                                      <li><FiCheck /> Full order process</li>
                                        <li><FiCheck />  500 orders/month</li>
                                        <li><FiCheck /> 2+ warehouses locations</li>
                                        <li><FiCheck />  Real time availability</li>
                                        <li><FiCheck />  Payment integration</li>
                                        <li><FiCheck />  Support </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancePricingOne
