import React, {useEffect} from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { FiArrowRight } from "react-icons/fi";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderTwo from '../common/header/HeaderTwo';
import FooterThree from '../common/footer/FooterThree';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from '../elements/timeline/TimelineTwo';
import TabOne from '../elements/tab/TabOne';
import ServiceMobile from "../elements/service/ServiceMobile";
import AboutThree from '../elements/about/AboutThree';



const Business = () => {
    return (
        <>
            <SEO title="Mobile solutions - Rental platform for lifts, construction and heavy equipment industries." />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-3 height-850">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h4 className="title theme-gradient">Mobile <br/>bookings</h4>
                                    <p className="description">Process orders automatically, create invoices at the touch of a button.<br/>Receive bookings and payments online 24/7.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                    <HashLink smooth to="/#pricing" className="btn-default btn-medium btn-icon">Purchase Now <FiArrowRight /> </HashLink>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-4">
                                <div className="thumbnail">
                                    <img src="./images/about/about-1.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <ServiceMobile 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                {/* End Service Area  */}


                {/* Start Slider Area  */}
                {/* <div className="slider-area slider-style-3 height-850">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h4 className="title theme-gradient">Trending <br />Business</h4>
                                    <p className="description">We help our clients succeed by creating brand identities, digital experiences, and print materials.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Purchase Now <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-4">
                                <div className="thumbnail">
                                    <img src="./images/about/about-1.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Slider Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Features"
                                    title = "Mobile App"
                                />
                            </div>
                        </div>
                        <TabOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "How it works"
                                        title = "Order Process"
                                        description = "Our goal is to automate the rental process <br /> in your company."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="dark-line"  />
                            </div>
                        </div>
                    </div>
                </div>

                <AboutThree />

                <FooterThree />
                {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//code.tidio.co/0mn8jrubuoyljnvb6lhb9ifzasa9ghia.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []
  )
}

            </main>
            
        </>
    )
}
export default Business;