import React from 'react';
import { FiSearch, FiCamera, FiCreditCard, FiCalendar, FiCheckSquare, FiShoppingCart} from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiSearch />,
        title: 'Search Products',
        description: 'Possibility to search for products by name, category and technical parameters.'
    },
    {
        icon: <FiCalendar />,
        title: 'Availability Calendar',
        description: 'Real-time preview of product availability in a given region or warehouse. '
    },
    {
        icon: <FiCheckSquare />,
        title: 'Filtering Product',
        description: 'Quick ability to find the product we need based on technical parameters and details.'
    },
    {
        icon: <FiShoppingCart />,
        title: 'Extensive price lists',
        description: 'Many options to configure price list variants based on the rental length or cooperation.'
    },
    {
        icon: <FiCreditCard />,
        title: 'Online Payments',
        description: 'Possibility to integrate online payments operator to pay by credit card or quick bank transfer.'
    },
    {
        icon: <FiCamera />,
        title: 'Photo/Video',
        description: 'Add photos and videos of your products to attract customers even more.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;