import React from 'react';

const AboutTwo = () => {
    return (
        <div className="service-area rn-section-gapTop">
        <div className="container">
            <div className="row align-items-center">
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                            <div className="inner text-center">
                            <h4 className="subtitle">SALES OFFICE DASHBOARD</h4>
                            <img className="" src="./images/about/about-2.png" alt="About Images" />
                            <p align="justify"><br></br>On Admin dashboard you can easy make full management of the system. You can add new products, machines categories and warehouses. In real time you can check analytics data about orders and sales income.    </p>
                    </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
