import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Increase Revenues<br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Reduce costs.",
                                        "Reduce staff.",
                                        "Improve cash flow.",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p align="justify"> Our rental software offers you:<br></br> -Fully-featured, brandable mobile app with webshop for your rental business. You can customize your online store with colours, logos, and design that reflects your signature brand. <br></br>-Complete control over your rental business with features like product displays, pricing, warehouse settings and calendar of availability.<br></br> -Automated order process thanks to integrated fast online payments API,<br></br> -Setting up your mobile and web store platform with our support team, <br></br> -Present your rental products in the best way possible. </p>
                            <div className="call-to-btn text-center mt--60">
                                <Link className="btn-default btn-icon" to="/contact">Ask about system <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
