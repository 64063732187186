import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap" id="link1">
            <div className="container">
                <div className="row row--30 align-items-right">
                    <div className="col-lg-4">
                        <div className="row row--15 service wrapper">
                            <img className="w-70" src="./images/about/about-1.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="theme-gradient">Fully-customizable </h2> <h3 className="title">Rental web store and mobile app cloud platform solution. </h3>
                                <p align="justify"> In the circular economy where we go from buying stuff to renting or subscribing for a lot of products, it has to be smooth and easy. Both for the end customers and for your rental business. That is the best way to make profitable rental and activity based businesses.</p>
                                <p align="justify">Our rental software comprises an online store that you can use as part of your website or as a standalone webshop and mobile app for smartphones with products/services catalog showing availability and delivery costs integrated with payment solutions optimized for online bookings. </p>
                                <p align="justify">Allow customers to order items or book services from an online store that supports real-time calendar availability, endless ways to present products, and accessories sales to boost your revenue.</p>
                                <p> </p>
                                <div className="call-to-btn text-center mt--60">
                                    <a className="btn-default" href="/contact">Request DEMO</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
