import React, {useEffect} from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import {InlineWidget} from 'react-calendly'
import { HashLink } from 'react-router-hash-link';

const Contact = () => {
    return (
        <>
            <SEO title="Contact || Rental solutions for lifts, construction and heavy equipment industries." />
            <Layout>
            <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                    <div className="col-lg-12">
                        <div className="row align-items-center">
                            <div className="inner text-center">
                            <h3 className="subtitle">Meet our product</h3>
                            <p className="description">If you would like to <b>make a demo appointment</b>, you can simply book a <b>free meeting online</b> here and experience MOBI Rental from the customer’s point of view. Let's talk together about <b>your needs</b> and <b>our rental solutions</b> for yours business. </p>
                            <InlineWidget url="https://calendly.com/mobi_rental/30min"  />
                            <p className="description">In the online demo, we show you the possible uses of MOBI Rental from both the customer’s and the rental business’s perspective. If required, a test account can be created live with you in the demo appointment. It will be individually created for your bunsiness and handed over to you.</p> 
                            <p className="description">No suitable appointment or you just have some questions?</p> <HashLink smooth to="/contact#info" className="btn-default"><b>Call us or send an e-mail</b></HashLink>
                            </div>
                    </div>
                </div>
            </div>   
        </div>

            </Layout>
            {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//code.tidio.co/0mn8jrubuoyljnvb6lhb9ifzasa9ghia.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []
  )
}
        </>
    )
}
export default Contact;