import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FcRight } from "react-icons/fc";


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=tPlsvxWypKY',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div id="howitworks" className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Optimize your business <br /> with <strong>our rental platform!</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                        <FcRight />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Complete rental platform</h4>
                                            <p className="text">Online platform with product/services packages and variants, advanced availability calendar and different ways to display your inventory offer.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                        <FcRight />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Booking and payment tool</h4>
                                            <p className="text">Get a full intuitive booking system optimized for web and mobile online bookings and a flexible payment system with a lot of payment options.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                        <FcRight />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Real-time availability</h4>
                                            <p className="text">Use advanced calendars and schedulers to present products availability in real-time and avoid double bookings.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                        <FcRight />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Customizable</h4>
                                            <p className="text">Customize your web and mobile store with the right product description, images, videos and other information about product/service to make your store descriptive.</p>
                                        </div>
                                    </li>
                                </ul>
                               {/*<div className="about-btn mt--30">
                                    <a className="btn-default" href="#">Check Demo</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
