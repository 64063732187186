import React, {useEffect} from 'react';
import SEO from "../common/SEO";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import HeaderTwo from '../common/header/HeaderTwo';
import ServiceOne from '../elements/service/ServiceOne';
import AboutFour from '../elements/about/AboutFour';
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne';
import BrandFour from '../elements/brand/BrandFour';
import FooterThree from '../common/footer/FooterThree';
import TimelineTwo from '../elements/timeline/TimelineTwo';

const BannerData = [
    {
        image: "/images/bg/bg-image-4.jpg",
        title: "Web Store <br /> for rental.",
        description: "Innovative Rental Platform <br /> Boosting Your Company's Efficiency!"
    },
]


const WebAgency = () => {
    return (
        <>
            <SEO title="Webstore solutions - Rental platform for lifts, construction and heavy equipment industries." />
            <main className="page-wrapper">
            <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-8 height-650">
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner text-center">
                                            <span className="subtitle theme-gradient">RENTAL SOFTWARE</span>
                                            <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                            <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                            <div className="button-group mt--30">
                                            <HashLink smooth to="#howitworks" className="btn-default btn-large round">How it works</HashLink>
                                                <a className="btn-default btn-border btn-large round" href="/demo">Arrange demo</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* End Slider Area  */}

                <AboutFour image="./images/bg/bg-image-4.jpg" />

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                    <div className="text-center">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h8 className="title">Improve customer service with web rental platform</h8>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--30">                             
                                <img className="w-100" src="./images/about/about-3.png" alt="Images" />
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gapTop">
                    <div className="container">
                        <ServiceOne 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "How it works"
                                        title = "Order Process"
                                        description = "Our goal is to automate the rental process <br /> in your company."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="dark-line"  />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Elements Area  */}
                <div className="rwt-pricingtable-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row mt--80">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Pricing Plan"
                                    title = "Web Pricing Plan."
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row mt--30">
                            <div className="col-lg-8 offset-lg-2">
                                <AdvancePricingOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "They trust us"
                                        title = "Our Clients"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandFour brandStyle="brand-style-2 variation-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                {/* End Brand Area  */}

                <FooterThree />
                {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//code.tidio.co/0mn8jrubuoyljnvb6lhb9ifzasa9ghia.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []
  )
}

            </main>
        </>
    )
}
export default WebAgency;