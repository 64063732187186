import React, {useEffect} from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ContactOne from "../elements/contact/ContactOne";

const Contact = () => {
    return (
        <>
            <SEO title="Contact || Rental solutions for lifts, construction and heavy equipment industries." />
            <Layout>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">

                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
            {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//code.tidio.co/0mn8jrubuoyljnvb6lhb9ifzasa9ghia.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []
  )
}
        </>
    )
}
export default Contact;