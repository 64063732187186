import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What is MOBI Rental ? How does it work?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body><p align="justify"> We are software product company based on 30+ years rental business experience in lifts and heavy equipment industry. MOBI Rental it's a platform with complete solutions for online rental process and equipment/orders management inside company.</p> <p> Now you can implement our solutions in your rental business.</p> </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Can I personalize brand, logo and graphics in app? 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Of course we can customize platform for your company needs. Add logotypes, branding and design that reflects your signature.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    How can I get the customer support?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body><p align="justify">We offer full support for our customers. If you have any questions regarding to the implementation of the system or technical questions about features and integrations, feel free to contact our support team via live chat on the website.</p> </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    How can I buy the solution?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body><p align="justify"> You need to choose the optimal one solution for your company from the plans we offer.</p> <p><HashLink smooth to="#pricing">Check out the specifications of plans <b>here.</b></HashLink> </p></Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

