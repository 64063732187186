import React from 'react';

const Features = () => {
    return (

<div className="col-lg-10">
<h5 className="mb--10">All-in mobile and web store:</h5>
<p align="justify" className="mb--10" >Online platform complete with advanced calendar, product/services packages and variants, and different ways to display your inventory.</p>
<h5 className="mb--10">No coding required:</h5>
<p align="justify" className="mb--10">Set up your store in a few steps using customizable templates, add the brand’s colors and rental offer. Enable your customers to start renting your products right away.</p>
<h5 className="mb--10">Multi language platform:</h5>
<p align="justify" className="mb--10">We offer many language and translations versions of the platform e.g. English, German, Spanish, Polish</p>
<h5 className="mb--10">Booking and payment tool:</h5>
<p align="justify" className="mb--10">Get a full intuitive booking system optimized for web and mobile online bookings and a flexible payment system with a lot of payment options.</p>
<h5 className="mb--10">Customizable:</h5>
<p align="justify" className="mb--10">Customize your web and mobile store with the right product description, images, videos and other information about product/service to make your store descriptive.</p>
<h5 className="mb--10">Real-time availability:</h5>
<p align="justify" className="mb--10">Use advanced calendars and schedulers to present products in real-time and avoid double bookings.</p>
<h5 className="mb--10">Multiple locations (warehouses):</h5>
<p align="justify" className="mb--10">Expand business by adding multiple locations, company branches.</p>
<h5 className="mb--10">Dynamic product prices lists:</h5>
<p align="justify" className="mb--10">You can specify the types of product price lists, daily, weekly and monthly rates. Wide range of setting options.</p>
<h5 className="mb--10">Offer variants and bundles:</h5>
<p align="justify" className="mb--10">Offer clients multiple variants of the same product with options such as sizing, colors, and model. Create packages of many products.</p>
<h5 className="mb--10">Responsive design:</h5>
<p align="justify" className="mb--80">Our online store is designed to adjust to various mobile devices and screen sizes to provide your visitors with maximum satisfaction.</p>
</div>
    )
}

export default Features