import React, { useEffect } from 'react';
import Typed from 'react-typed';
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterThree from '../common/footer/FooterThree';
import AboutOne from '../elements/about/AboutOne';
import Reviews from '../elements/calltoaction/Reviews';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import PricingFive from '../elements/pricing/PricingFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Mission from "../elements/about/Mission";
import ServiceZero from "../elements/service/ServiceZero";
import ExpSection from "../elements/service/ExpSection";
import BrandFour from '../elements/brand/BrandFour';
import Features from '../elements/service/Features';

const BusinessConsulting2 = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//code.tidio.co/0mn8jrubuoyljnvb6lhb9ifzasa9ghia.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <SEO title="Rental solutions for lifts, construction and heavy equipment industries." />
            <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
            <SliderSection t={t} />
            <Reviews />
            <ServiceSection />
            <AboutOne />
            <FeaturesSection />
            <CallToActionSection />
            <Mission />
            <PricingSection />
            <BrandSection />
            <FooterThree />
        </>
    );
};

const SliderSection = ({ t }) => (
    <div className="slider-area slider-style-1 bg-transparent height-850">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="inner text-center">
                        <span className="subtitle">{t("Title1")}</span>
                        <h1 className="title theme-gradient display-one">
                            We will make your rent online<br />
                            <Typed
                                strings={["For Lifts.", "For Equipment.", "For Service."]}
                                typeSpeed={80}
                                backSpeed={5}
                                backDelay={1000}
                                loop
                            />
                        </h1>
                        <p className="description">Allow customers to rent your products from anywhere with branded mobile app and web store.</p>
                        <div className="button-group">
                        <HashLink smooth to="/#link1" className="btn-default btn-medium round btn-icon">How it works <FiArrowRight /></HashLink>
                            <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const ServiceSection = () => (
    <div className="rn-service-area rn-section-gap">
        <div className="container">
            <ServiceZero
                serviceStyle="service__style--2"
                textAlign="text-center"
            />
        </div>
    </div>
);

const FeaturesSection = () => (
    <div className="service-area rn-section-gapTop">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="content">
                        <h2 className="title">Platform features:</h2>
                    </div>
                </div>
                <div className="col-lg-10">
                    <Features />
                </div>
            </div>
        </div>
    </div>
);

const CallToActionSection = () => (
    <div className="rwt-callto-action-area">
        <div className="wrapper">
            <CalltoActionFive />
        </div>
    </div>
);

const PricingSection = () => (
    <div className="rwt-pricing-area rn-section-gap">
        <div className="container">
            <div className="row mb--40 mb_sm--0">
                <div className="col-lg-12">
                    <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        subtitle="Check features"
                        title="Platform Plan"
                        description=""
                    />
                </div>
            </div>
            <PricingFive />
        </div>
    </div>
);

const BrandSection = () => (
    <div className="rwt-brand-area rn-section-gap">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <SectionTitle
                        textAlign="text-center"
                        radiusRounded=""
                        subtitle="They trust us"
                        title="Our Clients"
                        description=""
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 mt--10">
                    <BrandFour brandStyle="brand-style-2 variation-2" />
                </div>
            </div>
        </div>
    </div>
);

export default BusinessConsulting2;
