import React from 'react';
import {InlineWidget} from 'react-calendly'
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row mt--40 row--15">
            <div className="col-lg-10">
                                <div className="content">
                                    <h2 className="title">Contact form:</h2>
                                </div>
                            </div>
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>

                                    {/* Start Service Area  */}
                                    <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                    <div className="col-lg-12">
                        <div className="row align-items-center">
                            <div className="inner text-center">
                            <h3 className="subtitle">Meet our product</h3>
                            <p className="description">If you would like to <b>make a demo appointment</b>, you can simply book a <b>free meeting online</b> here and experience MOBI Rental from the customer’s point of view. <br></br> Let's talk together about <b>your needs</b> and <b>our rental solutions</b> for yours business. </p>
                            <InlineWidget url="https://calendly.com/mobi_rental/30min"  />
                            <p className="description">In the online demo, we show you the possible uses of MOBI Rental from both the customer’s and the rental business’s perspective. If required, a test account can be created live with you in the demo appointment. It will be individually created for your bunsiness and handed over to you.</p> 
                            <p className="description">No suitable appointment or you just have some questions? <br></br> Call us or send an e-mail.</p>
                            </div>
                    </div>
                </div>
            </div>   
        </div>
                {/* End Service Area  */}

            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div id="info" className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">WhatsApp</h4>
                                        <p><a href="https://api.whatsapp.com/send/?phone=48664446268&text=Hello,%20it%27s+a+beautiful+day!&type=phone_number&app_absent=0">+48 664 446 268</a></p>
                                        <p><br></br></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href="mailto:office@mobirental.eu">office@mobirental.eu</a></p>
                                        <p><br></br></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Office</h4>
                                        <p>Al. Jana Pawła II 43A/37B </p>
                                        <p>01-001 Warsaw, Poland (UE)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactOne;