import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    );
};

const ContactForm = ({ formStyle }) => {
    const [result, showResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        emailjs
            .sendForm(
                "service_794iyhn", // Twój ID usługi
                "template_g314u3h", // Twój ID szablonu
                e.target,
                "TqWhSdCM6NA_Dm8ui" // Twój klucz użytkownika
            )
            .then(
                () => {
                    setLoading(false);
                    showResult(true);
                    e.target.reset();
                    setTimeout(() => showResult(false), 5000); // Ukryj wiadomość po 5 sekundach
                },
                (error) => {
                    setLoading(false);
                    setError("Failed to send the message. Please try again later.");
                    console.error(error.text);
                }
            );
    };

    return (
        <form className={`${formStyle}`} onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="full_name"
                    placeholder="Company Name"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                />
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                ></textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large" disabled={loading}>
                    {loading ? "Sending..." : "Send message"}
                </button>
            </div>

            {result && (
                <div className="form-group">
                    <Result />
                </div>
            )}
            {error && <p className="error-message">{error}</p>}
        </form>
    );
};

export default ContactForm;
