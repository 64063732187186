import React from 'react';
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next'

const Nav = () => {
    const { t } = useTranslation();
    return (
        <nav>
            <ul className="mainmenu">
                <li className="has-droupdown">
                    <Link to="#">{t("Solutions")}</Link>
                    <ul className="submenu">
                        <li><Link to="/mobileapp">{t("Mobile app")}</Link></li>
                        <li><Link to="/webstore">{t("Web store")}</Link></li>
                        <li><Link to="/dashboard">{t("Dashboard module")}</Link></li>
                    </ul>
                </li>
                <li className="has-droupdown">
                    <Link to="#">{t("Industries")}</Link>
                    <ul className="submenu">
                        <li><Link to="/lifts-rental">{t("Lifts rental")}</Link></li>
                        <li><Link to="/heavy-equipment">{t("Heavy equipment")}</Link></li>
                        <li><Link to="/tools-rental">{t("Tools rental")}</Link></li>
                    </ul>
                </li>
                <li><HashLink smooth to="/#pricing">{t("Pricing")}</HashLink></li>
                <li><Link to="/contact">{t("Contact")}</Link></li>
            </ul>
        </nav>
    );
};

export default Nav;