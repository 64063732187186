import React, { useState } from 'react';
import i18n from '../i18n';
import Flag from 'react-world-flags';

const LanguageSelector = () => {
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    const chooseLanguage = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language);
        setIsOpen(false);
    };

    return (
        <div className="dropdown">
            <button
                onClick={toggleMenu}
                className="btn dropdown-toggle d-flex align-items-center btn-lg" // Usunięcie btn-primary i dodanie btn-lg
                type="button"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-expanded={isOpen ? 'true' : 'false'}
                style={{ backgroundColor: 'transparent', border: '1px solid #ccc' }} // Usunięcie tła i dodanie obramowania
            >
                {/* Wyświetlanie flagi dla wybranego języka */}
                <Flag code={selectedLanguage} style={{ width: 20, height: 15, marginRight: '8px' }} />
                {selectedLanguage.toUpperCase()}
            </button>
            {isOpen && (
                <ul
                    className="dropdown-menu"
                    aria-labelledby="languageDropdown"
                    style={{ display: 'block', position: 'absolute', zIndex: 10 }}
                >
                    <li>
                        <button
                            onClick={() => chooseLanguage('de')}
                            className="dropdown-item d-flex align-items-center"
                        >
                            <Flag code="de" style={{ width: 20, height: 15, marginRight: '8px' }} />
                            DE
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => chooseLanguage('us')}
                            className="dropdown-item d-flex align-items-center"
                        >
                            <Flag code="us" style={{ width: 20, height: 15, marginRight: '8px' }} />
                            US
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => chooseLanguage('it')}
                            className="dropdown-item d-flex align-items-center"
                        >
                            <Flag code="it" style={{ width: 20, height: 15, marginRight: '8px' }} />
                            IT
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => chooseLanguage('pl')}
                            className="dropdown-item d-flex align-items-center"
                        >
                            <Flag code="pl" style={{ width: 20, height: 15, marginRight: '8px' }} />
                            PL
                        </button>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default LanguageSelector;
