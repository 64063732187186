import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageScrollTop from './components/pageToTop/PageScrollTop';
import CookieConsent, { Cookies } from "react-cookie-consent";

// Pages import Here 
import Splash from "./pages/BusinessConsulting2";
import Mobile from "./pages/Mobile";
import Webstore from "./pages/Webstore";
import Dashboard from "./pages/Dashboard";
import Contact from "./pages/Contact";
import Demo from "./pages/Demo";

// Elements import Here 
import Contacter from "./elements/contact/Contacter";
import Error from "./pages/Error";

// Import Css Here 
import './assets/scss/style.scss';

const App = () => {
    return (
        <>
            <Helmet>
                {/* 100% privacy-first analytics */}
                <script async src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
            </Helmet>
            <Router>
                <PageScrollTop>
                    <Switch>
                        <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Splash} />
                        <Route path={`${process.env.PUBLIC_URL + "/mobileapp"}`} exact component={Mobile} />
                        <Route path={`${process.env.PUBLIC_URL + "/webstore"}`} exact component={Webstore} />
                        <Route path={`${process.env.PUBLIC_URL + "/dashboard"}`} exact component={Dashboard} />
                        <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact} />
                        <Route path={`${process.env.PUBLIC_URL + "/demo"}`} exact component={Demo} />
                        <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error} />
                    </Switch>
                </PageScrollTop>
                <CookieConsent
                    location="bottom"
                    declineButtonText="Decline"
                    cookieName="myAppCookieConsent"
                    style={{ background: "#242424", color: "#FFF" }}
                    buttonStyle={{ background: "#3399FF", color: "#fff", fontSize: "15px" }}
                    expires={150}
                >
                    We use cookies to improve your experience. By using our website, you agree to the use of cookies as described in our Privacy Policy.
                </CookieConsent>
            </Router>
        </>
    );
};

export default App;
