import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderTwo from '../common/header/HeaderTwo';
import FooterThree from '../common/footer/FooterThree';


import ServiceMobile from "../elements/service/ServiceMobile";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import AboutTwo from "../elements/about/AboutTwo";
import SlipThree from "../elements/split/SlipThree";
import TabOne from "../elements/tab/TabOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import AboutThree from '../elements/about/AboutThree';



const Finance = () => {
    return (
        <>
            <SEO title="MOBI Rental - Solutions for lifts, construction and heavy equipment industries." />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-start">
                                    <h4 className="subtitle">ADMIN DASHBOARD</h4>
                                    <h1 className="title display-one">Manage Platform <br />Add products.</h1>
                                    <div className="button-group mt--40 mt_sm--20">
                                        <a className="btn-default1" href="/demo">PURCHASE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <ServiceMobile 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                {/* End Service Area  */}

                <AboutTwo />

                {/* Start Elements Area  */}
{/*                 <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipThree />
                    </div>
                </div> */}
                {/* End Elements Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "How it works"
                                        title = "Order Process"
                                        description = "Our goal is to automate the rental process <br /> in your company."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="dark-line"  />
                            </div>
                        </div>
                    </div>
                </div>
                <AboutThree />

                <FooterThree />
                {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//code.tidio.co/0mn8jrubuoyljnvb6lhb9ifzasa9ghia.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []
  )
}

            </main>
        </>
    )
}

export default Finance;
